/* This stylesheet generated by Transfonter (https://transfonter.org) on August 6, 2017 11:26 PM */

@font-face {
	font-family: 'Bicubik';
	src: url('Bicubik.eot');
	src: local('Bicubik'),
		url('Bicubik.eot?#iefix') format('embedded-opentype'),
		url('Bicubik.woff') format('woff'),
		url('Bicubik.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

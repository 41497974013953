@import "./fonts.css";

#root{
}

html, body {
    margin: 0;
}

* {
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Bicubik", sans-serif;
    margin: 0;
}